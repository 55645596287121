.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  &__backdrop {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
  }
  &__imagebox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 70%;
    height: 80%;
    @media only screen and (max-width: $extra-large) {
      width: 80%;
      height: 80%;
    }
    @media only screen and (max-width: $large) {
      width: 90%;
      height: 90%;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__close-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 5rem;
    color: $color1;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      color: $color3;
    }
  }
}
