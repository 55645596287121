.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color4;
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width: $medium) {
      justify-content: space-around;
    }
  }
  &__box {
    width: 30rem;
    height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $medium) {
      width: 100%;
      height: 30rem;
    }
    @media only screen and (max-width: $small) {
      width: 100%;
      height: 20rem;
      text-align: center;
      height: 18rem;
    }
  }
  &__logo {
    width: 20rem;
    @media only screen and (max-width: $extra-small) {
      width: 15rem;
    }
  }
  &__textwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $small) {
      flex-wrap: wrap;
    }
  }
  &__textbox {
    display: flex;
    flex-direction: column;
    width: 20rem;
    height: 15rem;
  }
  &__link {
    text-decoration: none;
    font-size: 1.5rem;
    color: $color1;
    margin: 0.5rem 0;
    transition: all 0.3s;
    &:hover {
      color: $color3;
    }
  }
  &__icon {
    font-size: 4rem;
    transition: all 0.3s;
    &:hover {
      transform: scale(0.95);
    }
  }
  &__rights {
    text-align: center;
    background: $color2;
    color: $color5;
    &--link {
      text-decoration: none;
      color: $color1;
      transition: all 0.2s;
      &:hover {
        color: $color4;
      }
    }
  }
}
