.about {
  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image {
    height: 50rem;
    margin: 0 4rem 2rem 0;
    float: left;
    box-shadow: 0px 5px 14px -4px #000000;
    @media only screen and (max-width: $medium) {
      width: 100%;
      height: auto;
    }
  }
  &__text {
    width: 75%;
    margin-top: 10rem;
  }
  &__p {
    color: $color2;
    margin-bottom: 4rem;
  }
  &__firstLetter {
    &::first-letter {
      font-size: 4rem;
      font-weight: bold;
    }
  }
  &__owner-imagebox {
    position: relative;
    border-radius: 50%;
    width: 30rem;
    height: 30rem;
    overflow: hidden;
  }
  &__owner-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
  &__owner-name {
    text-transform: capitalize;
    font-weight: 400;
    margin-top: 2rem;
  }
  &__owner-title {
    color: $color3;
    text-transform: capitalize;
    font-size: 3rem;
    font-style: italic;
    font-weight: 400;
  }
  &__map-wrap {
    width: 100rem;
  }
  &__message {
    width: 100%;
    background-attachment: fixed;
    color: $color1;
    padding: 10rem 0;
    margin: 5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $medium) {
      background-size: cover;
    }
    &--content {
      width: 80%;
    }
    &--h5 {
      color: $color1;
      font-weight: 300;
      font-size: 3rem;
      margin-bottom: 1rem;
      @media only screen and (max-width: $medium) {
        font-size: 2.5rem;
      }
    }
    &--h3 {
      font-size: 4rem;
      color: $color1;
      text-align: left;
      margin-bottom: 4rem;
      @media only screen and (max-width: $medium) {
        font-size: 3rem;
      }
    }
    // &--button {
    // }
    &--title {
      color: $color1;
      font-weight: 100;
      font-size: 2rem;
      padding: 1rem;
    }
  }
}

.leaflet-container {
  width: 60%;
  height: 50rem;
  margin: 3rem 0;
  z-index: 0;
}
