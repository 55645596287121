.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 10rem 0;
  width: 80%;
  height: 55rem;
  grid-gap: 1.5rem;
  @media only screen and (max-width: $extra-large) {
    width: 90%;
    height: 50rem;
  }
  @media only screen and (max-width: $extra) {
    height: 45rem;
  }
  @media only screen and (max-width: $medium) {
    width: 99%;
  }
  @media only screen and (max-width: $small) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr repeat(3, 1fr) 2fr;
    height: 180rem;
    width: 90%;
  }
  &__imagebox {
    overflow: hidden;
    cursor: pointer;
    filter: brightness(80%);
    transition: all 0.5s;
    @media only screen and (max-width: $medium) {
      filter: brightness(100%);
    }
    &:hover {
      filter: brightness(100%);
    }
    &:active {
      transform: scale(0.97);
    }
    &--1 {
      grid-column: 1/3;
      grid-row: 1/3;
      @media only screen and (max-width: $small) {
        grid-column: inherit;
        grid-row: inherit;
      }
    }
    // &--2 {
    // }
    &--3 {
      grid-column: 4/6;
      @media only screen and (max-width: $small) {
        grid-column: inherit;
        grid-row: inherit;
      }
    }
    &--4 {
      grid-column: 3/5;
      @media only screen and (max-width: $small) {
        grid-column: inherit;
        grid-row: inherit;
      }
    }
    // &--5 {
    // }
  }
  &__images {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.flex {
  &__imagebox {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    margin: 10rem 0;
    justify-content: center;
  }
  &__images__box {
    margin: 5px;
    width: 300px;
    height: 500px;
  }
  &__images {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.paragraph-style {
  background: $color4;
  font-size: 2.9rem;
  width: 80%;
  margin-bottom: 10rem;
  padding: 2rem;
  @media only screen and (max-width: $extra-large) {
    width: 90%;
  }
}
