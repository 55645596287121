.swiper {
  position: relative;
  height: 100vh;
  width: 100%;
  &__image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    //filter: brightness(0.6);
  }
  &__textbox {
    width: 50rem;
    height: 20rem;
    position: absolute;
    right: 8%;
    bottom: 10%;
    // cursor: pointer;
    @media only screen and (max-width: $small) {
      bottom: 20%;
      right: auto;
      left: 5%;
    }
    @media only screen and (max-width: $extra-small) {
      width: 40rem;
    }
  }
}

///SWIPER ARROWS
.btn-next-section {
  position: absolute;
  left: 10%;
  bottom: 0;
  font-size: 8rem;
  font-weight: bold;
  color: $color1;
  z-index: 10;
  cursor: pointer;
  @media only screen and (max-width: $small) {
    bottom: 5%;
  }
}
.swiper-button-next {
  position: absolute;
  right: 3%;
  bottom: 25%;
  font-size: 8rem;
  font-weight: bold;
  z-index: 10;
  color: $color1;
  cursor: pointer;
  @media only screen and (max-width: $small) {
    display: none;
  }
}
.swiper-button-prev {
  position: absolute;
  left: 3%;
  bottom: 25%;
  font-size: 8rem;
  font-weight: bold;
  z-index: 10;
  color: $color1;
  cursor: pointer;
  @media only screen and (max-width: $small) {
    display: none;
  }
}
.swiper-pagination {
  height: 3rem;
  width: 5rem;
  position: absolute;
  right: 5%;
  bottom: 5%;
  z-index: 10;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media only screen and (max-width: $small) {
    bottom: 8%;
  }
}
// .swiper-pagination-clickable {
// }
.swiper-pagination-bullet {
  background: $color1;
  width: 2px;
  height: 3rem;
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  height: 6rem;
}
