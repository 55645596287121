$color1: #fff;
$color2: #000;
$color3: #ccc;
$color4: #383e42;
$color5: #828282ce;
$color6: #6d757a;

$extra-small: 21.9em;
$small: 37.5em;
$medium: 48em;
$large: 62em;
$extra: 75em;
$extra-large: 90em;
// @media only screen and (max-width: $extra-small) {...};

//////////////////////////////////////////////////
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;

  @media only screen and (max-width: $extra) {
    font-size: 60%;
  }
  @media only screen and (max-width: $large) {
    font-size: 50%;
  }
  @media only screen and (max-width: $medium) {
    font-size: 45%;
  }
  @media only screen and (max-width: $small) {
    font-size: 40%;
  }
  @media only screen and (max-width: $extra-small) {
    font-size: 35%;
  }
}

body {
  font-family: "Segoe UI", "Open Sans", Helvetica, sans-serif;
}

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

h1 {
  color: $color1;
  font-size: 4rem;
  text-transform: uppercase;
  margin: 2rem 0;
  text-shadow: 0px 10px 9px rgb(105, 105, 105);
}
h2 {
  position: relative;
  text-align: center;
  color: $color2;
  font-size: 3.5rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 5rem 0;
  width: 100%;
  &::before {
    position: absolute;
    content: "";
    border-bottom: 8px solid $color2;
    border-radius: 20px;
    width: calc(50% - 20.5rem);
    left: 0;
    top: 50%;
  }
  &::after {
    position: absolute;
    content: "";
    border-bottom: 8px solid $color2;
    border-radius: 20px;
    width: calc(50% - 20.5rem);
    right: 0;
    top: 50%;
  }
}

h3 {
  color: $color2;
  text-align: center;
  font-size: 2.2rem;
  text-transform: uppercase;
  width: 100%;
  @media only screen and (max-width: $medium) {
    font-size: 1.8rem;
  }
}
h4 {
  color: $color1;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 1rem;
  &::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid $color1;
    width: 7rem;
    margin-top: 3rem;
  }
}
h5 {
  width: 80%;
  color: $color2;
  font-size: 2rem;
  @media only screen and (max-width: $medium) {
    width: 100%;
  }
}
p {
  color: $color1;
  font-size: 2rem;
}

.btn {
  background: $color6;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 300;
}

.active {
  border-bottom: 2px solid $color1;
  @media only screen and (max-width: $small) {
    border-bottom: 2px solid $color1;
  }
}
.hidden {
  display: none;
}
