.contact {
  &__title-box {
    margin-top: 14rem;
    position: relative;
    background: $color4;
    width: 80%;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
    @media only screen and (max-width: $small) {
      width: 100%;
      margin-top: 5rem;
    }
  }
  &__title {
    color: $color1;
    margin: 6rem 0;
  }
  &__absolute--box {
    position: absolute;
    right: 1rem;
    bottom: -2.5rem;
    z-index: 3;
  }
  &__absolute--title {
    color: $color1;
    font-weight: 300;
    font-size: 2.5rem;
    padding: 1rem;
    @media only screen and (max-width: $medium) {
      display: none;
    }
  }
  &__absolute-image {
    position: absolute;
    bottom: 0;
    height: 25rem;
    left: 5%;
    @media only screen and (max-width: $medium) {
      display: none;
    }
  }
  &__info-box {
    background: $color6;
    display: flex;
    width: 50%;
    margin-top: -1rem;
    z-index: 2;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
    margin-bottom: 14rem;
    @media only screen and (max-width: $medium) {
      width: 70%;
      margin-bottom: 5rem;
    }
    @media only screen and (max-width: $small) {
      width: 90%;
    }
    @media only screen and (max-width: $extra-small) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
  &__info-card {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  &__image {
    height: 8rem;
    width: 8rem;
    margin: 1rem 0;
  }
  &__link {
    color: $color1;
    text-decoration: none;
    font-size: 2rem;
    text-align: center;
  }
}

.big-icons {
  font-size: 10rem;
  color: $color6;
  margin: 0 2rem;
  transition: transform 0.3s;
  @media only screen and (max-width: $medium) {
    font-size: 5rem;
  }
  &:hover {
    transform: scale(1.1);
  }
}
