.cli {
  display: flex;
  justify-content: center;
  align-self: center;
  &__swipbox {
    max-width: 120rem;
    position: relative;
    @media only screen and (max-width: $extra) {
      width: 100%;
    }
  }
  &__imgbox {
    text-align: center;
    margin: 3rem 0;
  }
  &__img {
    height: 15rem;
    align-self: center;
    @media only screen and (max-width: $extra) {
      height: 12rem;
    }
  }
  &__next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    cursor: pointer;
    z-index: 5;
  }
  &__prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    cursor: pointer;
    z-index: 5;
  }
}
