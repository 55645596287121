.cards-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  width: 24rem;
  margin: 4rem 1rem;
  cursor: pointer;
  color: $color2;
  text-decoration: none;
  transition: all 0.3s;
  @media only screen and (max-width: $extra-small) {
    width: 90%;
  }
  &:hover {
    transform: scale(1.01);
  }
  &:active {
    transform: scale(0.99);
  }
  &__image {
    width: 100%;
  }
  &__title {
    font-size: 2rem;
    // text-transform: capitalize;
  }
  &__text {
    font-size: 1.3rem;
    color: $color2;
    @media only screen and (max-width: $extra-small) {
      font-size: 2rem;
    }
  }
}
