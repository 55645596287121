.home {
  position: relative;
  &__logo {
    position: absolute;
    left: 3%;
    top: 3rem;
    z-index: 10;
    width: 20rem;
    @media only screen and (max-width: $small) {
      top: 5rem;
    }
  }
}
