@mixin btn {
  position: fixed;
  right: 5rem;
  top: 5rem;
  width: 5rem;
  height: 5rem;
  z-index: 10;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
}

.burger-btn {
  @include btn;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $color2;
  border-radius: 50%;
  &__icon {
    width: 100%;
    color: $color2;
    font-size: 4rem;
  }
}
.menu-btn {
  @include btn;
  background: transparent;
  border: none;
  &__icon {
    color: $color1;
    font-size: 5rem;
  }
}

.nav {
  &__backdrop {
    background: rgba(75, 72, 72, 0.548);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
  }
  &__wrap {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 50vw;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 18% 100%);
    background: $color4;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (max-width: $extra) {
      width: 60vw;
    }
    @media only screen and (max-width: $large) {
      width: 70vw;
    }
    @media only screen and (max-width: $medium) {
      width: 80vw;
    }
    @media only screen and (max-width: $small) {
      width: 100vw;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      background: rgba(56, 62, 66, 0.89);
    }
  }
  &__linkbox {
    width: 80%;
    height: 80%;
    margin-left: 15rem;
    transform: skewX(13deg);
    display: flex;
    @media only screen and (max-width: $small) {
      transform: skewX(0);
      justify-content: center;
      margin-left: 0;
    }
  }
  &__listbox {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: $extra-large) {
      margin-top: 5rem;
    }
    @media only screen and (max-width: $small) {
      margin-top: 10rem;
      height: 70%;
      align-items: center;
    }
  }
  &__list {
    margin-top: 2rem;
    transform: skewX(-13deg);
    list-style: none;
    &--child {
      margin-top: 1rem;
    }
    transition: all 0.3s;
    &:hover {
      transform: skewX(-13deg) scale(1.1);
      @media only screen and (max-width: $small) {
        transform: skewX(0) scale(1);
      }
    }
    @media only screen and (max-width: $small) {
      transform: skewX(0);
    }
  }
  &__link {
    position: relative;
    color: $color1;
    text-transform: uppercase;
    font-size: 3rem;
    text-decoration: none;
    transition: all 0.3s;

    @media only screen and (max-width: 1367px) {
      font-size: 2rem;
    }
    @media only screen and (max-width: $medium) {
      font-size: 3rem;
    }
    @media only screen and (max-width: $small) {
      font-size: 3.5rem;
    }
    &:hover {
      color: $color3;
    }

    &--child {
      font-size: 1.5rem;
      margin-left: 3rem;
      line-height: 0rem;
      text-transform: capitalize;
      @media only screen and (max-width: $small) {
        text-align: center;
        font-size: 2.5rem;
        line-height: 3.5rem;
        margin-left: 0;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80%;
    margin: 5%;
    &--linksbox {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--icons {
      font-size: 3rem;
      color: $color1;
      margin: 1rem 1rem 0 0;
      transition: all 0.2s;
      &:hover {
        color: $color3;
      }
    }
    &--textbox {
      width: 50%;
    }
    &--text {
      font-size: 1.5rem;
      color: $color5;
    }
    &--link {
      text-decoration: none;
      color: $color1;
      transition: all 0.2s;
      &:hover {
        color: $color3;
      }
    }
    &--span {
      font-size: 4rem;
      font-weight: 100;
      color: $color1;
      margin: 0 1rem;
    }
  }
}
