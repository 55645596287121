.pro {
  display: flex;
  justify-content: center;
  align-items: center;
  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__upperbox {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    &:last-child::before {
      content: "";
      border-bottom: 1px solid rgba(0, 0, 0, 0.123);
      width: 100%;
      left: 0;
      top: 50%;
      @media only screen and (max-width: $medium) {
        display: none;
      }
    }
    &:after {
      content: "";
      position: absolute;
      border-left: 1px solid rgba(0, 0, 0, 0.123);
      height: 100%;
      @media only screen and (max-width: $medium) {
        display: none;
      }
    }
  }
}
.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1) rotate(5deg);
  }
}
.image-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.233) 19%,
    rgba(111, 111, 111, 1) 100%
  );
  color: $color1;
  font-size: 2.5rem;
  padding: 2rem;
  font-weight: 400;
  @media only screen and (max-width: $extra-large) {
    font-size: 2rem;
  }
  @media only screen and (max-width: $extra) {
    font-size: 1.5rem;
    padding: 1rem;
  }
}
.single {
  position: relative;
  overflow: hidden;
  width: 60rem;
  height: 60rem;
  margin: 2rem;
  @media only screen and (max-width: $extra-large) {
    width: 50rem;
    height: 50rem;
  }
  @media only screen and (max-width: $extra) {
    width: 40rem;
    height: 40rem;
  }
  @media only screen and (max-width: $small) {
    width: 100%;
    margin: 0;
  }
}

.multi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__imgbox {
    position: relative;
    overflow: hidden;
    width: 60rem;
    height: 28rem;
    margin: 2rem;
    @media only screen and (max-width: $extra-large) {
      width: 50rem;
      height: 23rem;
    }
    @media only screen and (max-width: $extra) {
      width: 40rem;
      height: 18rem;
    }
    @media only screen and (max-width: $small) {
      width: 100%;
    }
  }
}

