.service {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__wrap {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 3rem 0;

    &:not(:last-child)::after {
      position: absolute;
      text-align: center;
      content: "";
      border-bottom: 1px solid $color3;
      bottom: -3rem;
      left: 20%;
      right: 20%;
    }
    &--flex-direction {
      flex-direction: row-reverse;
    }
    @media only screen and (max-width: $medium) {
      flex-direction: column;
    }
  }
  &__imagebox {
    flex-shrink: 0;
    position: relative;
    width: 35rem;
    height: 40rem;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
    &::before {
      content: "";
      position: absolute;
      border-bottom: 8px solid $color1;
      width: 100%;
      top: 50%;
      left: 0;
    }
    &::after {
      content: "";
      position: absolute;
      border-left: 8px solid $color1;
      height: 100%;
      top: 0;
      left: 50%;
    }
    &:hover > .service__title {
      width: 100%;
      height: 100%;
    }
    &:hover::before,
    &:hover::after {
      opacity: 0;
    }
  }
  &__image {
    height: 100%;
    text-align: center;
  }
  &__title {
    width: 50%;
    height: 50%;
    background: rgba(65, 62, 62, 0.664);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all 0.5s;
  }
  &__h3 {
    padding: 1rem;
    font-weight: 200;
    font-size: 3.2rem;
    text-align: center;
    overflow-wrap: break-word;
    font-weight: 400;
    color: $color1;
  }
  &__textbox {
    width: 50%;
    @media only screen and (max-width: $medium) {
      width: 80%;
      margin: 3rem;
    }
  }
  &__p {
    margin-top: 2rem;
    color: $color2;
  }
}
