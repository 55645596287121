.ser {
  display: flex;
  justify-content: center;
  align-items: center;
  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 120rem;
  }
  &__card {
    width: 30rem;
    height: 30rem;
    margin: 5rem;
    text-align: center;
    @media only screen and (max-width: $extra) {
      height: 20rem;
      width: 20rem;
    }
    @media only screen and (max-width: $medium) {
      width: 15rem;
      height: 15rem;
    }
  }
  &__img {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.26);
    margin-bottom: 2rem;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__link {
    font-size: 3rem;
    text-decoration: none;
    color: $color2;
    text-align: center;
    transition: all 0.3s;
    margin-top: 5rem;
    &:hover {
      color: $color3;
    }
  }
}
